import { BASE_URL, DEFAULT_LOCALE } from '@src/typescriptGlobals/constants';

type Params = {
  languageCode: string | null;
  slug: string;
  baseURL?: string;
};

// Helper function to construct URLs correctly
const constructURL = ({ languageCode, slug, baseURL = BASE_URL }: Params): string => {
  // Ensure BASE_URL does not have a trailing slash
  const cleanBaseURL = baseURL.replace(/\/$/, '');

  // Ensure slug starts with exactly one slash
  const cleanSlug = slug.startsWith('/') ? slug : `/${slug}`;

  // Handle locale path
  const localePart =
    languageCode?.toLowerCase() === DEFAULT_LOCALE ? '' : `/${languageCode?.toLowerCase()}`;

  // Construct full URL while keeping the protocol intact
  return `${cleanBaseURL}${localePart}${cleanSlug}`;
};

export default constructURL;
