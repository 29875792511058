'use client';

import React from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import isFullUrl from '@src/utils/isFullUrl';
import { NextLinkPropTypes } from './NextLink.types';
const DEFAULT_LOCALE = 'en';
const NextLink: React.FC<NextLinkPropTypes> = ({
  className,
  locale,
  href = '/',
  children,
  ariaLabel,
  replace,
  title,
  target = '_self'
}) => {
  const params = useParams<{
    locale: string;
  }>();
  const newLocale = locale ? locale : params?.locale;
  const localizedHref = newLocale === DEFAULT_LOCALE ? href : `/${newLocale}${href}`;
  if (isFullUrl({
    url: href
  })) {
    return <a className={`cursor-pointer ${className}`} href={href} aria-label={ariaLabel} target='_blank' title={title}>
        {children}
      </a>;
  } else if (href.startsWith('#')) {
    return <span className={`cursor-default ${className}`} aria-label={ariaLabel} title={title}>
        {children}
      </span>;
  } else {
    return <Link className={`cursor-pointer ${className}`} replace={replace} href={localizedHref} aria-label={ariaLabel} title={title} target={target}>
        {children}
      </Link>;
  }
};
export default NextLink;