import React from 'react';
import NextLink from '../NextLink';
import isEmpty from '@src/utils/isEmpty';
import isFullUrl from '@src/utils/isFullUrl';
import { RenderBodyCopyPropTypes } from './RenderBodyCopy.types';
import InspectorWrapper from '../InspectorWrapper';
const RenderBodyCopy: React.FC<RenderBodyCopyPropTypes> = ({
  entryId = '',
  bodyCopy,
  className = ''
}) => {
  if (isEmpty({
    value: bodyCopy
  })) return null;
  const renderContent = (contentNodes: any[]): React.ReactNode[] => {
    return contentNodes?.map((node, idx) => {
      if (node.nodeType === 'text') {
        let textContent: React.ReactNode = node.value;

        // Apply text formatting marks
        node.marks?.forEach((mark: any) => {
          switch (mark.type) {
            case 'bold':
              textContent = <strong key={`bold-${idx}`} className='font-[500]'>
                  {textContent}
                </strong>;
              break;
            case 'italic':
              textContent = <em key={`italic-${idx}`} className='italic'>
                  {textContent}
                </em>;
              break;
            case 'underline':
              textContent = <u key={`underline-${idx}`} className='!underline'>
                  {textContent}
                </u>;
              break;
            case 'superscript':
              textContent = <sup key={`superscript-${idx}`}>{textContent}</sup>;
              break;
            case 'subscript':
              textContent = <sub key={`subscript-${idx}`}>{textContent}</sub>;
              break;
            default:
              break;
          }
        });
        return textContent;
      }
      if (node.nodeType === 'hyperlink') {
        const isExternal = isFullUrl({
          url: node.data?.uri
        });
        const Tag = isExternal ? 'a' : NextLink;
        return <Tag key={`link-${idx}`} href={node.data?.uri} target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined} className='font-[400] underline'>
            {renderContent(node.content)}
          </Tag>;
      }
      if (node.nodeType === 'paragraph') {
        return renderContent(node.content || []);
      }
      return null;
    });
  };
  const renderLists = (node: any, idx: number): React.ReactNode => {
    const isOrderedList = node.nodeType === 'ordered-list';
    const Tag = isOrderedList ? 'ol' : 'ul';
    const listClass = isOrderedList ? 'list-decimal' : 'list-disc';
    return <Tag key={`list-${idx}`} className={`ml-[2.4rem] [&>*:not(:last-child)]:mb-[0.5rem] ${listClass}`} data-sentry-element="Tag" data-sentry-component="renderLists" data-sentry-source-file="RenderBodyCopy.tsx">
        {node.content.map((listItem: any, listIdx: number) => <li key={`list-item-${listIdx}`} className=''>
            {listItem.content.map((paragraphNode: any) => renderContent([paragraphNode]))}
          </li>)}
      </Tag>;
  };
  const renderParagraphs = (bodyCopyNodes: any[]) => {
    return bodyCopyNodes.map((node, idx) => {
      if (node.nodeType === 'paragraph') {
        return <p key={`paragraph-${idx}`}>{renderContent(node.content)}</p>;
      }
      if (node.nodeType === 'unordered-list' || node.nodeType === 'ordered-list') {
        return renderLists(node, idx);
      }
      if (node.nodeType === 'heading-2') {
        return <h2 key={`heading-2-content-${idx}`} className='mb-[2.4rem] mt-[2.4rem] text-[2.2rem] font-[400] leading-[1.3] md:mt-[4.4rem] xl:text-[2.8rem]'>
            {node?.content?.[0]?.value}
          </h2>;
      }
      return renderContent([node]); // Fallback for other types
    });
  };
  return <InspectorWrapper entryId={entryId} fieldId='bodyCopy' data-sentry-element="InspectorWrapper" data-sentry-component="RenderBodyCopy" data-sentry-source-file="RenderBodyCopy.tsx">
      <div className={`[&>*:not(:last-child)]:mb-[1.6rem] ${className} `}>
        {renderParagraphs(bodyCopy)}
      </div>
    </InspectorWrapper>;
};
export default RenderBodyCopy;